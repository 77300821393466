<template>
    <div class="pageContol aeAdvisoryNotice">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">助学工具</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        @click="$router.push({path:'/web/miniApp/testDataList'})"
                    >备考资料</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >{{title}}</a>
                </span>
            </div>
            <!-- 编辑内容 -->
            <div class="framePage-body">
                <el-form
                    ref="form"
                    :rules="rules"
                    :model="ruleForm"
                    label-width="120px"
                >
                    <el-form-item
                        label="名称："
                        prop="materialName"
                    >
                        <el-input
                            size="small"
                            placeholder="请输入名称"
                            v-model="ruleForm.materialName"
                            maxlength="45"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="所属课程分类："
                        prop="materialClassify"
                    >
                        <el-cascader
                            :options="miniCourseTypeList"
                            v-model="ruleForm.materialClassify"
                            :props="miniProps"
                            size="small"
                            clearable
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item
                        label="上传电子资料："
                        prop="fileUrl"
                    >
                        <el-upload
                            :on-change="uploadFile"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="upload-demo"
                            action
                            accept="*.pdf"
                        >
                            <el-button
                                size="small"
                                class="bgc-bv"
                            >点击上传</el-button>
                            <div
                                slot="tip"
                                class="el-upload__tip"
                                v-show="ruleForm.fileUrl"
                            >
                                已选文件: <el-link
                                    :href="ruleForm.fileUrl"
                                    type="primary"
                                    target="_blank"
                                >{{ruleForm.fileId}}</el-link>
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item
                        label="排序："
                        prop="materialSort"
                    >
                        <el-input
                            v-model="ruleForm.materialSort"
                            size="small"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            maxlength="9"
                            placeholder="请输入排序"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="是否上架："
                        prop="materialState"
                    >
                        <el-radio-group v-model="ruleForm.materialState">
                            <el-radio label="10">是</el-radio>
                            <el-radio label="20">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item class="czzjf">

                    </el-form-item>
                </el-form>
                <div class="flexjc">
                    <el-button
                        class="bgc-bv"
                        @click="cancel"
                    >取消</el-button>
                    <el-button
                        type="primary"
                        class="bgc-bv"
                        @click="save"
                    >确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
export default {
    name: "testDataAdd",
    mixins: [UploadPic],
    data() {
        return {
            // 消息详情数据
            ruleForm: {
                materialName: "", // 资讯名称
                materialClassify: "",
                fileUrl: "",
                fileId: "",
                materialSort: "",
                materialState: "20",
            },
            // 校验输入
            rules: {
                materialName: [
                    {
                        required: true,
                        message: "请输入资讯标题",
                        trigger: "blur",
                    },
                ],
                materialClassify: [
                    {
                        required: true,
                        message: "请选择课程分类",
                        trigger: "change",
                    },
                ],
                fileUrl: [
                    {
                        required: true,
                        message: "请上传电子资料",
                        trigger: "change",
                    },
                ],
                materialSort: [
                    {
                        required: true,
                        message: "请输入排序",
                        trigger: "blur",
                    },
                ],
                materialState: [
                    {
                        required: true,
                        message: "请选择是否上架",
                        trigger: "change",
                    },
                ],
            },
            title: "新增资料",
            miniCourseTypeList: [], //小程序分类
            miniProps: {
                label: "name",
                value: "courseTypeId",
                checkStrictly: false,
                emitPath: false,
            },
            materialId: "",
        };
    },
    mounted() {},
    computed: {},
    created() {
        this.getcourseTypeArrData();
        if (this.$route.query.materialId) {
            this.materialId = this.$route.query.materialId;
            this.query(this.materialId);
            this.title = "编辑资料";
        }
    },
    methods: {
        // 取消
        cancel() {
            this.$router.back();
        },
        uploadFile(res) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "TEMP");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            formData.append("isPublic ", true);
            const isLt2M = res.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过100MB!");
                return false;
            } else {
                this.$Postformat("/sys/upload", formData)
                    .then((result) => {
                        this.ruleForm.fileId = result.data.fileKey;
                        this.ruleForm.fileUrl = result.data.fileURL;
                        this.fileName = res.name;
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.$message({
                                type: "warning",
                                message: "上传失败",
                            });
                        }, 300);
                    });
            }
        },
        save() {
            let params = {
                fileId: this.ruleForm.fileId,
                fileUrl: this.ruleForm.fileUrl,
                materialClassify: this.ruleForm.materialClassify,
                materialName: this.ruleForm.materialName,
                materialSort: this.ruleForm.materialSort,
                materialState: this.ruleForm.materialState,
            };
            let url = "";
            if (this.materialId) {
                url = "/biz/study-material/updateMaterial";
                params.materialId = this.materialId;
            } else {
                url = "/biz/study-material/saveMaterial";
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$post(url, params, 3000, true, 2).then((res) => {
                        if (res.status == "0") {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            setTimeout(() => {
                                this.$router.push({
                                    path: "/web/miniApp/testDataList",
                                });
                            }, 1000);
                        }
                    });
                }
            });
        },
        //
        query(materialId) {
            this.$post(
                "/biz/study-material/queryById",
                { materialId },
                3000,
                true,
                2
            ).then((res) => {
                ;
                this.ruleForm.fileId = res.data.fileId;
                this.ruleForm.fileUrl = res.data.fileUrl;
                this.ruleForm.materialClassify = res.data.materialClassify;
                this.ruleForm.materialName = res.data.materialName;
                this.ruleForm.materialSort = res.data.materialSort;
                this.ruleForm.materialState = res.data.materialState;
                this.materialId = res.data.materialId;
            });
        },
        //获取课程分类数组数据
        getcourseTypeArrData() {
            this.$post(
                "miniapp/coursetype/selectTree",
                { hiddenState: "10" },
                3000,
                true,
                2
            ).then((ret) => {
                this.miniCourseTypeList = this.delchild(ret.data);
            });
        },
        delchild(list = []) {
            for (let i = 0; i < list.length; i++) {
                const el = list[i];
                if (el.children.length == 0) {
                    delete el.children;
                }
                this.delchild(el.children);
            }
            return list;
        },
    },
};
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 18px;
}
</style>
<style lang="less">
.upload-demo {
    .el-upload {
        height: 40px !important;
        border: 0;
    }
}
</style>